import http from '../api/http-common';

class userInfoDataService {
	getAll() {
		return http.get('/tutorials');
	}

	getSameID(id) {
		return http.get(`/userinfo/${id}`);
	}

	signup(data) {
		return http.post('/auth/signup', data);
	}

	signin(data) {
		return http.post('/auth/signin', data);
	}

	logout(data) {
		return http.get('/auth/logout', data);
	}

	update(id, data) {
		return http.put(`/tutorials/${id}`, data);
	}

	delete(id) {
		return http.delete(`/tutorials/${id}`);
	}

	deleteAll() {
		return http.delete(`/tutorials`);
	}

	findByTitle(title) {
		return http.get(`/tutorials?title=${title}`);
	}

	storeUserData(token) {
		localStorage.setItem('user', JSON.stringify(token));
	}
	//로컬 스토리지 삭제 key값에 들어가는 내용은 local storage에서 key 이름 넣어주면 됩니다.
	removeStorage(key) {
		localStorage.removeItem(key);
	}
	getUserCoupons() {
		return http.get('/coupons');
	}
}

export default new userInfoDataService();
