<template>
	<nav class="breadcrumb">
		<ol class="breadcrumb__textbox">
			<li v-for="(crumb, ci) in crumbs" :key="ci">
				<button :class="{ disabled: isLast(ci) }" @click="selected(crumb)">
					{{ crumb.name }}
				</button>
			</li>
		</ol>
	</nav>
</template>

<script>
export default {
	data() {
		return {
			crumbIndex: 0,
		};
	},
	props: {
		crumbs: {
			type: Array,
			required: true,
		},
	},
	computed: {
		crumbsLen() {
			return this.crumbs.length;
		},
	},
	methods: {
		isLast(index) {
			return index === this.crumbs.length - 1;
		},
		selected(crumb) {
			this.$emit('selected', crumb);
		},
	},
};
</script>

<style></style>
