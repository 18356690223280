<template>
	<div>
		<!-- 데스크탑에서의 navbar -->
		<nav class="navbar__pc">
			<div class="navbar_menu1">
				<div class="navbar_logo">
					<router-link to="/" class="logo"><img :src="logoUrl" /></router-link>
				</div>
				<ul class="navbar_menu">
					<li class="menu_link" @click="showSubmenu1">
						서비스
						<ul
							class="menu_sub_list"
							v-show="this.submenu1"
							id="sub_item--large"
						>
							<li>
								<router-link to="/service" class="sub_item" id="sub_item--bold"
									>서비스</router-link
								>
							</li>
							<li>
								<router-link to="/servicecrypto" class="sub_item"
									>암호 스키마</router-link
								>
							</li>
							<li>
								<router-link to="/servicesecurity" class="sub_item"
									>모듈러스</router-link
								>
							</li>
							<li>
								<router-link to="/serviceBiMining" class="sub_item"
									>BI-Mining</router-link
								>
							</li>
							<li>
								<router-link to="/servicevaccine" class="sub_item"
									>백신 FC-Anti Virus</router-link
								>
							</li>
						</ul>
					</li>
					<li class="menu_link" @click="showSubmenu2">
						솔루션
						<ul
							class="menu_sub_list"
							v-show="this.submenu2"
							id="sub_item--large"
						>
							<li>
								<router-link to="/solutioncrypto" class="sub_item"
									>암호 스키마</router-link
								>
							</li>
							<li>
								<router-link to="/solutionvaccine" class="sub_item"
									>백신 FC-Anti Virus</router-link
								>
							</li>
						</ul>
					</li>
					<li class="menu_link" @click="showSubmenu3">
						요금
						<ul
							class="menu_sub_list"
							v-show="this.submenu3"
							id="sub_item--large"
						>
							<li>
								<router-link to="/pricing" class="sub_item"
									><strong>요금</strong></router-link
								>
							</li>
							<!-- <li>
								<router-link to="/pricingcrypto" class="sub_item"
									>암호 스키마</router-link
								>
							</li> -->
							<li>
								<router-link to="/pricingsecurity" class="sub_item"
									>모듈러스</router-link
								>
							</li>
							<li>
								<router-link to="/pricingbimining" class="sub_item"
									>BI Mining</router-link
								>
							</li>
							<!-- <li>
								<router-link to="/pricingvaccine" class="sub_item"
									>백신 FC-Anti Virus</router-link
								>
							</li> -->
						</ul>
					</li>
					<li class="menu_link" @click="showSubmenu4">
						제품 사용방법
						<ul class="menu_sub_list" v-show="this.submenu4" id="sub_item--sm">
							<li>
								<router-link to="/userguide" class="sub_item"
									>이용안내</router-link
								>
							</li>
							<li>
								<router-link to="/warning" class="sub_item"
									>개발자 페이지</router-link
								>
							</li>
						</ul>
					</li>
				</ul>
			</div>

			<div class="navbar_menu2">
				<ul class="menu_cs">
					<li @click="showCsmenu" class="menu_cs--title">
						고객센터
						<ul class="menu_sub_list" v-show="csMenu">
							<li>
								<router-link to="/inquiry" class="sub_item"
									>문의하기</router-link
								>
							</li>
							<li>
								<router-link to="/faq" class="sub_item"
									>자주 하는 질문</router-link
								>
							</li>
						</ul>
					</li>
				</ul>

				<!-- 로그인 성공 시 헤더에 표시 및 분기처리 -->
				<!-- 1. 로그인 시 -->
				<template v-if="isUserlogin">
					<span
						class="mainheader__text--com"
						v-if="this.$store.state.userCom == 1"
						>기업 회원
					</span>
					<span
						class="mainheader__text--personal"
						v-if="this.$store.state.userCom == 0"
						>개인 회원
					</span>
					<span class="mainheader__text--id">{{ $store.state.userid }}</span
					><span class="mainheader__text">&nbsp;&nbsp;님&nbsp;&nbsp;</span>
					<router-link to="/mypage" class="mainheader__text"
						>마이 페이지</router-link
					>
					<span>&nbsp;｜&nbsp;</span>
					<a href="javascript:;" @click="logoutUser" class="mainheader__text"
						>로그아웃</a
					>
				</template>

				<!-- 2. 로그아웃 시 -->
				<template v-else>
					<router-link to="/login"
						><button id="btn-login" class="btn--sm btn--border-white">
							로그인
						</button></router-link
					>
					<router-link to="/signup"
						><button id="btn-signup" class="btn--sm btn--fill-white">
							회원가입
						</button></router-link
					>
				</template>
			</div>
		</nav>
		<div class="global-overlay" v-show="menuActive"></div>
		<!-- 태블릿, 모바일에서의 navbar -->
		<nav class="navbar__mb">
			<div class="navbar_menu1">
				<div class="navbar_logo">
					<router-link to="/" class="logo"><img :src="logoUrl" /></router-link>
					<!-- 태블릿, 모바일에서의 햄버거 메뉴 -->
					<burger-menu
						@toggle-menu="menuActive = !menuActive"
						:active="menuActive"
					></burger-menu>
				</div>
				<div id="navbarNav" v-show="menuActive">
					<div class="navbar-nav">
						<div class="main_area">서비스</div>
						<div class="sub_area">
							<router-link to="/service"><strong>서비스</strong></router-link>
							<router-link to="/servicecrypto">암호 스키마</router-link>
							<router-link to="/servicesecurity">모듈러스</router-link>
							<router-link to="/serviceBiMining">BI Mining</router-link>
							<router-link to="/servicevaccine">백신 FC-Anti Virus</router-link>
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">솔루션</div>
						<div class="sub_area">
							<router-link to="/solutioncrypto">암호 스키마</router-link>
							<router-link to="/solutionvaccine"
								>백신 FC-Anti Virus</router-link
							>
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">요금</div>
						<div class="sub_area">
							<router-link to="/pricing"><strong>요금</strong></router-link>
							<!-- <router-link to="/pricingcrypto">암호 스키마</router-link> -->
							<router-link to="/pricingsecurity">모듈러스</router-link>
							<router-link to="/pricingbimining">BI Mining</router-link>
							<!-- <router-link to="/pricingvaccine">백신 FC-Anti Virus</router-link> -->
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">제품 사용방법</div>
						<div class="sub_area">
							<router-link to="/userguide">이용안내</router-link>
							<router-link to="/warning">개발자 페이지</router-link>
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">Modulus</div>
						<div class="sub_area">
							<router-link to="/warning">Modulus</router-link>
						</div>
					</div>

					<div class="navbar-nav">
						<div class="main_area">고객센터</div>
						<div class="sub_area">
							<router-link to="/inquiry">문의하기</router-link>
							<router-link to="/faq">자주 하는 질문</router-link>
						</div>
					</div>

					<!-- 로그인 성공 시 헤더에 표시 및 분기처리 -->
					<!-- 1. 로그인 시 -->
					<template v-if="isUserlogin">
						<div class="login-info">
							<router-link to="/mypage" class="mainheader__text"
								>마이 페이지</router-link
							>
							<span>&nbsp;&nbsp;｜&nbsp;&nbsp;</span>
							<a
								href="javascript:;"
								@click="logoutUser"
								class="mainheader__text"
								>로그아웃</a
							>
						</div>
					</template>

					<!-- 2. 로그아웃 시 -->
					<template v-else>
						<div class="navbar_buttons">
							<router-link to="/login"
								><button id="btn__login" class="btn--extra btn--border-white">
									로그인
								</button></router-link
							>
							<router-link to="/signup"
								><button id="btn__signup" class="btn--extra btn--fill-accent">
									회원가입
								</button></router-link
							>
						</div>
					</template>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
import userinfoDataService from '../services/userinfoDataService';
import logoUrl from '@/assets/images/new-fctslogoWhite.svg';
import Burger from '@/components/common/Burger.vue';

export default {
	components: {
		'burger-menu': Burger,
	},
	data: function () {
		return {
			logoUrl,
			submenu1: null,
			submenu2: false,
			submenu3: false,
			submenu4: false,
			menuActive: false,
			sublistActive: false,
			csMenu: false,
		};
	},
	computed: {
		// 로그인 분기 처리
		isUserlogin() {
			return this.$store.getters.isLogin;
		},
	},
	methods: {
		showSubmenu1() {
			this.submenu1 = !this.submenu1;
			this.submenu2 = false;
			this.submenu3 = false;
			this.submenu4 = false;
			this.csMenu = false;
		},
		showSubmenu2() {
			this.submenu2 = !this.submenu2;
			this.submenu1 = false;
			this.submenu3 = false;
			this.submenu4 = false;
			this.csMenu = false;
		},
		showSubmenu3() {
			this.submenu3 = !this.submenu3;
			this.submenu1 = false;
			this.submenu2 = false;
			this.submenu4 = false;
			this.csMenu = false;
		},
		showSubmenu4() {
			this.submenu4 = !this.submenu4;
			this.submenu1 = false;
			this.submenu2 = false;
			this.submenu3 = false;
			this.csMenu = false;
		},
		showCsmenu() {
			this.csMenu = !this.csMenu;
			this.submenu1 = false;
			this.submenu2 = false;
			this.submenu3 = false;
			this.submenu4 = false;
		},
		logoutUser() {
			// header logout
			this.$store.commit('clearUserid');
			userinfoDataService.removeStorage('user');
			userinfoDataService.removeStorage('vuex');
			location.reload();
		},
	},
};
</script>

<style></style>
